import { AxiosResponse } from "axios";
import {
  IRevaluationGetAttendanceByIdParams,
  IRevaluationCreateAttendancePayload,
  IRevaluationGetExamsPayload,
  IRevaluationRequestPayload,
  IRevaluationUpdateStatusPayload,
} from "./types";
import { call, put } from "redux-saga/effects";
import {
  loadFailure,
  loadSuccess,
  loadSuccessCreateAttendance,
  loadSuccessGetAttendanceById,
  loadSuccessGetExams,
  loadSuccessUpdateStatus,
} from "./actions";
import { apiSollar } from "../../../services/axios";
import { toast } from "react-toastify";

const path = "/revaluation"

export function* getPatientsForRevaluation({
  payload,
}: IRevaluationRequestPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${path}?limit=${
        payload.limit ? payload.limit : 10
      }&page=${payload.page || 1}&status=${payload.status}`,
    );

    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar informações da lista de Reavaliação");
    yield put(loadFailure());
  }
}

export function* getRevaluationAttendanceById({
  payload,
}: IRevaluationGetAttendanceByIdParams) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `${path}/show/${
        payload.external_attendance_id
      }`,
    );

    yield put(loadSuccessGetAttendanceById(response.data));
  } catch (error) {
    toast.error("Erro ao buscar informações do atendimento");
    yield put(loadFailure());
  }
}

export function* postAttendance({
  payload,
}: IRevaluationCreateAttendancePayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      `${path}`,
      {...payload}
      // add payload
    );

    yield put(loadSuccessCreateAttendance());
  } catch (error) {
    toast.error("Erro ao tentar criar um atendimento para Reavaliação");
    yield put(loadFailure());
  }
}

export function* updateAttendanceStatus({
  payload,
}: IRevaluationUpdateStatusPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.patch as any,
      `${path}/${payload._id}`,
      {status: payload.status}
    );

    yield put(loadSuccessUpdateStatus());
  } catch (error) {
    toast.error("Erro ao tentar atualizar o status do atendimento");
    yield put(loadFailure());
  }
}

export function* getExamsByAttendanceId({
  payload,
}: IRevaluationGetExamsPayload) {
  try {
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/exams/show?attendance_id=${payload.attendance}&page=${payload.page}&limit=${payload.limit}`,
    );

    yield put(loadSuccessGetExams(response.data));
  } catch (error) {
    toast.error("Erro ao tentar buscar exames do paciente");
    yield put(loadFailure());
  }
}
