import { JitsiMeeting } from "@jitsi/react-sdk";
import LOCALSTORAGE from "../../helpers/constants/localStorage";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { loadUserById } from "../../store/ducks/users/actions";
import { useDispatch } from "react-redux";

export default function JitsiCall() {
  const params = useParams();
  const attendanceId = params.id;
  const callType = params.type;
  const dispatch = useDispatch();
  const userState = useSelector((state: ApplicationState) => state.users.data);
  const telemedicineUrl = localStorage.getItem(LOCALSTORAGE.TELEMEDICINE_URL);
  const userId = localStorage.getItem(LOCALSTORAGE.USER_ID);
  
  const roomName = () => {
    if (callType === "revaluation") {
      return "R" + attendanceId;
    } else if (callType === "opinion") {
      return "O" + attendanceId;
    } else {
      return attendanceId
    }
  }
  

  useEffect(() => {
    dispatch(loadUserById(userId, "sidebar"));
  }, [params.id]);

  return (
    <JitsiMeeting
      domain={telemedicineUrl}
      roomName={roomName()}
      onReadyToClose={() => window.close()}
      configOverwrite={{
        startWithAudioMuted: true,
        disableModeratorIndicator: true,
        startScreenSharing: true,
        enableEmailInStats: false,
      }}
      userInfo={{
        displayName: userState.name,
        email: userState.email,
      }}
      getIFrameRef={(iframeRef) => {
        iframeRef.style.height = "100%";
        iframeRef.style.width = "100%";
      }}
    />
  );
}
