export enum PanelTypes {
  LOAD_REQUEST = "@panel/LOAD_REQUEST",
  LOAD_SUCCESS = "@panel/LOAD_SUCCESS",
  LOAD_REQUEST_ORIGIN = "@panel/LOAD_REQUEST_ORIGIN",
  LOAD_SUCCESS_ORIGIN = "@panel/LOAD_SUCCESS_ORIGIN",
  LOAD_FAILURE = "@panel/LOAD_FAILURE",
}

export interface IPanelParams {
  limit: number,
  page: number,
  origin_id: number;
  origin_name: string;
}

export interface IPanelList {
  id: number,
  patient_name: string,
  birthdate: string,
  medicament: string[],
  hr_medicament: string[],
  initial_date: string[],
  final_date: string[],
  period: string[],
  origin_id: number,
  dose: string[],
  diagnostics: string,
}

export interface IOriginList {
  id: number,
  origin_name: string
}

export interface PanelState {
  data: IPanelList[],
  origin: any,
  loading: boolean,
  success: boolean,
  error: boolean
}
