import { action } from "typesafe-actions";
import { RequestParamsDataSync } from "./sagas";
import { DataSyncInterface, DataSyncTypes } from "./types";

export const loadRequest = (data: RequestParamsDataSync) =>
  action(DataSyncTypes.LOAD_REQUEST, data);
export const loadRequestLogs = (data: RequestParamsDataSync) =>
  action(DataSyncTypes.LOAD_REQUEST_LOGS, data);
export const loadSuccessLogs = (data: RequestParamsDataSync) =>
  action(DataSyncTypes.LOAD_SUCCESS_LOGS, data);
export const loadRequestExamUrl = (data: RequestParamsDataSync) =>
  action(DataSyncTypes.LOAD_REQUEST_EXAM_URL, data);
export const loadSuccessExamUrl = (data: RequestParamsDataSync | string) =>
  action(DataSyncTypes.LOAD_SUCCESS_EXAM_URL, data);
export const loadSuccess = (data: DataSyncInterface[]) =>
  action(DataSyncTypes.LOAD_SUCCESS, data);
export const loadFailure = () => action(DataSyncTypes.LOAD_FAILURE);
