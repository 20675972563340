import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Table from "../../components/Table";
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  loadRequest,
  loadRequestOrigin,
} from "../../store/ducks/panel/actions";
import { ApplicationState } from "../../store";
import LOCALSTORAGE from "../../helpers/constants/localStorage";
import useScroll from "../../utils/useScroll";
import theme from "../../theme/theme";
import { checkViewPermission } from "../../utils/permissions";
import NoPermission from "../../components/Erros/NoPermission";
import { IOriginList } from "../../store/ducks/panel/types";

export default function Panel() {
  const dispatch = useDispatch();
  const currentCompany = localStorage.getItem(LOCALSTORAGE.COMPANY_SELECTED);
  const [scrollAuto, setScrollAuto] = useState<boolean>(true);
  const [origin, setOrigin] = useState<string>("1 - TODAS");
  const [originSelected, setOriginSelected] = useState<IOriginList>({
    id: 1,
    origin_name: "TODAS",
  });
  const limit = 3000;
  const tableContainer = useRef(null);
  const panelState = useSelector((state: ApplicationState) => state.panel);
  const rightsOfLayoutState = useSelector(
    (state: ApplicationState) => state.layout.data.rights
  );
  useEffect(() => {
    dispatch(loadRequestOrigin());
    dispatch(
      loadRequest({
        page: 1,
        limit: limit,
        origin_id: originSelected.id,
        origin_name: originSelected.origin_name,
      })
    );
  }, [currentCompany, origin]);

  const getTimeByLimit = (limit: number) => {
    const minLimit = 1;
    const maxLimit = 100;
    const minTime = 200;
    const maxTime = 1000;

    const normalizedTime = Math.max(minLimit, Math.min(maxLimit, limit));

    const time =
      minTime +
      ((maxTime - minTime) * (normalizedTime - minLimit)) /
        (maxLimit - minLimit);

    return time;
  };

  useScroll(tableContainer, getTimeByLimit(limit), scrollAuto);

  const handleChangeSelect = (event: SelectChangeEvent) => {
    const valueToFind = event.target.value.replace(/.*?\s*-\s*/, "");
    const foundOriginObject = panelState.origin.find(
      (origin) => origin.origin_name === valueToFind
    );
    setOriginSelected(foundOriginObject);
    setOrigin(event.target.value as string);
  };

  return (
    <Sidebar>
      {checkViewPermission("panel", JSON.stringify(rightsOfLayoutState)) ? (
        <Container
          ref={tableContainer}
          sx={{ width: "110%", overflowY: "scroll", maxHeight: 650 }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h5" fontWeight={700} mb={3} color="primary">
              Painel Assistencial
            </Typography>
            <FormControl margin="dense">
              <InputLabel id="origin-label">Origem</InputLabel>
              <Select
                labelId="origin-label"
                label="Origem"
                value={origin}
                onChange={handleChangeSelect}
                size="small"
                sx={{
                  backgroundColor: "#fff",
                  minWidth: 170,
                  height: 40,
                  labelColor: "#fb1",
                }}
              >
                {panelState?.origin?.length && panelState?.origin?.map((origin, i) => (
                  <MenuItem
                    key={i}
                    value={`${origin.id} - ${origin.origin_name}`}
                  >
                    {origin.id} - {origin.origin_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Button
            sx={{
              position: "absolute",
              right: 40,
              fontSize: 9,
              bottom: 10,
            }}
            variant="contained"
            onClick={() => {
              setScrollAuto(!scrollAuto);
            }}
          >
            Scroll automático:
            {scrollAuto ? (
              <Typography
                fontSize={8}
                fontWeight={600}
                marginLeft={0.5}
                color={theme.palette.success.main}
              >
                Ativado
              </Typography>
            ) : (
              <Typography
                fontSize={8}
                fontWeight={600}
                marginLeft={0.5}
                color={theme.palette.error.main}
              >
                Desativado
              </Typography>
            )}
          </Button>
          <Table
            tableCells={[
              { name: "Nome", align: "left" },
              { name: "DN", align: "left" },
              { name: "Origem", align: "center" },
              { name: "Medicação", align: "center" },
              { name: "Dose", align: "center" },
              { name: "Inicio", align: "center" },
              { name: "Final", align: "center" },
              { name: "Hora", align: "center" },
              { name: "Diagnósticos", align: "center" },
            ]}
            panelState={!panelState.loading && panelState.data}
          >
            {""}
          </Table>
        </Container>
      ) : (
        <NoPermission />
      )}
    </Sidebar>
  );
}
