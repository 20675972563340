import { action } from "typesafe-actions";
import { ITelemedicineAttendanceByIdParams, ITelemedicineAttendances, ITelemedicineAttendancesCreateMeetParams, ITelemedicineAttendancesCreateMeetResponse, ITelemedicineAttendancesGetBiomeasuresParams, ITelemedicineAttendancesGetBiomeasuresResponse, ITelemedicineAttendancesRequestParams, ITelemedicineAttendancesUpdateStatusParams, ITelemedicineCancelDocumentBody, ITelemedicineCreateDocumentBody, ITelemedicineDocs, ITelemedicineGetDocumentsParams, ITelemedicinesAttendancesDocumentListResponse, TelemedicineAttendancesTypes } from "./types";

export const loadRequest = (params: ITelemedicineAttendancesRequestParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST, params);

export const loadRequestCreateMeet = (params: ITelemedicineAttendancesCreateMeetParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_MEET, params);

export const loadRequestUpdateStatus = (params: ITelemedicineAttendancesUpdateStatusParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_UPDATE_STATUS, params);

export const loadRequestCreateDocument = (body: ITelemedicineCreateDocumentBody) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_DOCUMENT, body);

export const loadRequestGetDocumentsByType = (params: ITelemedicineGetDocumentsParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_GET_DOCUMENT, params);

export const loadRequestCancelDocument = (body: ITelemedicineCancelDocumentBody) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_CANCEL_DOCUMENT, body);

export const loadRequestGetBiomeasuresByAttendance = (params: ITelemedicineAttendancesGetBiomeasuresParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_GET_BIOMEASURES, params);

export const loadRequestAttendanceById = (params: ITelemedicineAttendanceByIdParams) =>
  action(TelemedicineAttendancesTypes.LOAD_REQUEST_ATTENDANCE_BY_ID, params);

export const loadSuccess = (data: ITelemedicineAttendances[]) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS, data);

export const loadSuccessCreateMeet = (data: ITelemedicineAttendancesCreateMeetResponse) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_CREATE_MEET, data);

export const loadSuccessUpdateStatus = (data: ITelemedicineAttendancesCreateMeetResponse) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_UPDATE_STATUS, data);

export const loadSuccessCreateDocument = () =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_CREATE_DOCUMENT);

export const loadSuccessGetDocumentsByType = (data: ITelemedicinesAttendancesDocumentListResponse) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_GET_DOCUMENT, data);

export const loadSuccessCancelDocument = () =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_GET_DOCUMENT);

export const loadSuccessGetBiomeasuresByAttendance = (data: ITelemedicineAttendancesGetBiomeasuresResponse) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_GET_BIOMEASURES, data);

export const loadSuccessAttendanceById = (data: ITelemedicineAttendances) =>
  action(TelemedicineAttendancesTypes.LOAD_SUCCESS_ATTENDANCE_BY_ID, data);

export const loadFailure = () => action(TelemedicineAttendancesTypes.LOAD_FAILURE);
