import React, { useEffect } from "react";
import Table from "../../../components/Table";
import DialogMedium from "../../../components/DialogMedium";
import ViewedDocumentHistory from "../viewedDocumentHistory";
import { useDispatch } from "react-redux";
import { loadRequest } from "../../../store/ducks/integrationDocuments/actions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { RequestParamsDataSync } from "../../../store/ducks/integrationDocuments/sagas";
import PaginationComponent from "../../../components/Pagination";

export default function EletronicalDocuments({ currentAttendance }: any) {
  const dispatch = useDispatch();
  const [openHistory, setOpenHistory] = React.useState(false);
  const [page, setPage] = React.useState<number | string>(1);
  const [limit, setLimit] = React.useState<number | string>(5);
  const [dataSyncId, setDataSyncId] = React.useState<number>(0);
  const [document, setDocument] = React.useState<any>(null);

  const integrationDocumentsState = useSelector(
    (state: ApplicationState) => state.integrationDocuments
  );

  const payload: RequestParamsDataSync = {
    data_sync_type: "DOCUMENT",
    external_patient_id: currentAttendance?.id_patient,
    page,
    limit,
  };

  useEffect(() => {
    dispatch(
      loadRequest({
        ...payload,
        end_date: "2030-01-01",
        initial_date: "2000-01-01",
      })
    );
  }, [currentAttendance, page, limit]);

  return (
    <>
      <DialogMedium
        title={document?.describe}
        open={document?.open}
        fullScreen
        handleClose={() => setDocument((curr) => ({ ...curr, open: false }))}
      >
        <iframe
          width="100%"
          height="95%"
          title={document?.describe}
          src={integrationDocumentsState?.data?.url}
          style={{ marginTop: -80 }}
        />
      </DialogMedium>
      <DialogMedium
        title="Histórico de Visualizações"
        open={openHistory}
        maxWidth="lg"
        handleClose={() => setOpenHistory(false)}
      >
        <ViewedDocumentHistory
          currentAttendance={currentAttendance}
          payload={{ ...payload, data_sync_id: dataSyncId }}
        />
      </DialogMedium>
      <Table
        tableCells={[
          { name: "Código", align: "left" },
          { name: "Documento Clínico", align: "left" },
          { name: "Empresa", align: "left" },
          { name: "Data/Hora de Realização", align: "left" },
          { name: "Ações", align: "left" },
        ]}
        actions
        actionsTypes={["history", "document"]}
        actionHandleHistory={(row) => {
          setOpenHistory(true);
          setDataSyncId(row?.data_sync_id);
        }}
        actionHandleDocument={(row) => {
          setDocument({ ...row, open: true });
          dispatch(
            loadRequest({
              ...payload,
              in_iframe: true,
              data_sync_id: row?.data_sync_id,
            })
          );
        }}
        integratedDocumentState={integrationDocumentsState}
      >
        {""}
      </Table>
      <PaginationComponent
        page={String(page)}
        rowsPerPage={String(limit)}
        totalRows={Number(integrationDocumentsState.data.total || 0)}
        handleFirstPage={() => setPage("1")}
        handleLastPage={() =>
          dispatch(
            loadRequest({
              ...payload,
              page: Math.ceil(
                +integrationDocumentsState.data.total / +limit
              ).toString(),
              limit: limit.toString(),
              initial_date: "2021-01-01",
              end_date: "2000-01-01",
            })
          )
        }
        handleNextPage={() => setPage(Number(page) + 1)}
        handlePreviosPage={() => setPage(Number(page) - 1)}
        handleChangeRowsPerPage={(event) => setLimit(event.target.value)}
      />
    </>
  );
}
