export enum TelemedicineAttendancesTypes {
  LOAD_REQUEST = "@telemedicineAttendance/LOAD_REQUEST",
  LOAD_REQUEST_CREATE_MEET = "@telemedicineAttendance/LOAD_REQUEST_CREATE_MEET",
  LOAD_SUCCESS = "@telemedicineAttendance/LOAD_SUCCESS",
  LOAD_SUCCESS_CREATE_MEET = "@telemedicineAttendance/LOAD_SUCCESS_CREATE_MEET",
  LOAD_REQUEST_UPDATE_STATUS = "@telemedicineAttendance/LOAD_REQUEST_UPDATE_STATUS",
  LOAD_SUCCESS_UPDATE_STATUS = "@telemedicineAttendance/LOAD_SUCCESS_UPDATE_STATUS",
  LOAD_REQUEST_CREATE_DOCUMENT = "@telemedicineAttendance/LOAD_REQUEST_CREATE_DOCUMENT",
  LOAD_SUCCESS_CREATE_DOCUMENT = "@telemedicineAttendance/LOAD_SUCCESS_CREATE_DOCUMENT",
  LOAD_REQUEST_GET_DOCUMENT = "@telemedicineAttendance/LOAD_REQUEST_GET_DOCUMENT",
  LOAD_SUCCESS_GET_DOCUMENT = "@telemedicineAttendance/LOAD_SUCCESS_GET_DOCUMENT",
  LOAD_REQUEST_CANCEL_DOCUMENT = "@telemedicineAttendance/LOAD_REQUEST_CANCEL_DOCUMENT",
  LOAD_REQUEST_GET_BIOMEASURES = "@telemedicineAttendance/LOAD_REQUEST_GET_BIOMEASURES",
  LOAD_REQUEST_ATTENDANCE_BY_ID = "@telemedicineAttendance/LOAD_REQUEST_ATTENDANCE_BY_ID",
  LOAD_SUCCESS_ATTENDANCE_BY_ID = "@telemedicineAttendance/LOAD_SUCCESS_ATTENDANCE_BY_ID",
  LOAD_SUCCESS_GET_BIOMEASURES = "@telemedicineAttendance/LOAD_SUCCESS_GET_BIOMEASURES",
  LOAD_SUCCESS_CANCEL_DOCUMENT = "@telemedicineAttendance/LOAD_SUCCESS_CANCEL_DOCUMENT",
  LOAD_FAILURE = "@telemedicineAttendance/LOAD_FAILURE",
}

export type ITelemedicineDocs =
  | "InitialCare"
  | "SimplePrescription"
  | "SpecialPrescription"
  | "MedicalCertificate";

export interface ITelemedicineAttendancesRequestParams {
  page: string | number;
  limit: string | number;
  attendance_type: "U" | "I" | string;
}

export interface ITelemedicineAttendancesRequestPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST;
  payload: ITelemedicineAttendancesRequestParams;
}

export interface ITelemedicineAttendancesCreateMeetParams {
  company_id: string | number;
  external_ids: {
    patient: string | number;
    attendance: string | number;
  };
  room_id?: string;
  provider?: {
    name: string;
    email: string;
  },
  patient?: {
    name: string;
    email: string;
  },
}

export interface ITelemedicineAttendancesCreateMeetPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_MEET;
  payload: ITelemedicineAttendancesCreateMeetParams;
}

export interface ITelemedicineAttendancesUpdateStatusParams {
  _id?: string;
  in?: {
    provider?: boolean | null;
    patient?: boolean | null;
  };
  finished_by?: string;
  patient_name?: string;
  birth_date?: string;
  gender?: string;
  mother_name?: string;
  age?: string;
  telemedicine_attendance_id?: string | number;
  address_header?: string;
  address_footer?: string;
  sus_card?: string;
  origin?: string;
  health_insurance?: string;
  date_impress?: string;
  password_panel?: string;
}

export interface ITelemedicineAttendancesUpdateStatusPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_UPDATE_STATUS;
  payload: ITelemedicineAttendancesUpdateStatusParams;
}

export interface ITelemedicineAttendances {
  id_attendance: number;
  id_medical_record: number;
  patient_name: string;
  address: string;
  birthdate: string;
  gender: string;
  marital_status: string;
  mother_name: string;
  ident_national: string;
  physic_national: string;
  phone_number: string;
  email: string;
  sector_name: string;
  specialty: string;
  employee_name: string;
  attendance_date: string;
  insurance: string;
  plan: string;
  classication_color: string;
  patient_url?: string | null;
  provider_url?: string | null;
  provider_in?: boolean | string;
  _id?: string;
  address_header?: string;
  address_footer?: string;
  age?: string;
  date_impress?: string;
  password_panel?: string;
  sus_card?: string;
  origin?: string;
  health_insurance?: string;
  room_id?: string;
}

export interface ITelemedicineCreateDocumentBody {
  telemedicine_attendance_id?: string;
  document_type: ITelemedicineDocs;
  content:
    | IInitialCareDocumentList<ITelemedicineAttendanceBiomeasures>
    | IPrescriptionDocumentList
    | IMedicalCertificateList;
}

export interface ITelemedicineCreateDocumentPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_DOCUMENT;
  payload: ITelemedicineCreateDocumentBody;
}

export interface ITelemedicineGetDocumentsParams {
  telemedicine_attendance_id?: string | number | null;
  document_type?: ITelemedicineDocs;
}

export interface ITelemedicineAttendancesGetBiomeasuresParams {
  attendance_id: string | number;
}

export interface ITelemedicineAttendancesGetBiomeasuresPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_GET_BIOMEASURES;
  payload: ITelemedicineAttendancesGetBiomeasuresParams;
}

export interface ITelemedicineAttendanceByIdParams {
  attendance_id: string | number;
}

export interface ITelemedicineAttendanceByIdPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_ATTENDANCE_BY_ID;
  payload: ITelemedicineAttendanceByIdParams;
}

export interface ITelemedicineAttendanceBiomeasures {
  alerts: string;
  main_complaint: string;
  pa: string;
  fc: string;
  fr: string;
  temperature: string;
  dextro: string;
  saturation: string;
  weight: string;
  priority: string;
  total?: string | number;
  rn?: string | number;
}

export interface ITelemedicineAttendancesGetBiomeasuresResponse {
  data: ITelemedicineAttendanceBiomeasures[];
  limit?: string;
  page?: string;
  total?: string;
}

export interface ITelemedicineGetDocumentsPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_GET_DOCUMENT;
  payload: ITelemedicineGetDocumentsParams;
}

export interface ITelemedicineCancelDocumentBody {
  canceled_reason: string;
  _id: string;
}

export interface ITelemedicineCancelDocumentPayload {
  type: typeof TelemedicineAttendancesTypes.LOAD_REQUEST_CANCEL_DOCUMENT;
  payload: ITelemedicineCancelDocumentBody;
}

export interface ITelemedicineAttendancesResponse {
  items: ITelemedicineAttendances[];
  attendance: ITelemedicineAttendances;
  page: string | number;
  limit: string | number;
  total: string | number;
}

export interface ITelemedicineAttendancesCreateMeetResponse {
  _id: string;
  external_ids: {
    patient: string;
    attendance: string;
  };
  room: {
    urls: {
      patient: string;
      provider: string;
    };
    in: {
      patient: string | null;
      provider: string | null;
    };
    id: string;
  };
  created_at: string;
}

export interface IInitialCareDocumentList<T> {
  main_diagnoses?: string;
  secondary_diagnoses?: string;
  violence?: boolean;
  open_field?: string;
}

export interface IPrescriptionDocumentList {
  open_field?: string;
}

export interface IMedicalCertificateList {
  days?: string;
  date?: null | Date | string;
  open_field?: string;
  cid?: string;
}

export interface ITelemedicinesAttendancesDocumentListResponse {
  _id: string;
  document_type: ITelemedicineDocs;
  content: any;
  created_at?: string;
  created_by?: string;
  canceled?: boolean;
  canceled_reason?: string;
  canceled_at?: string;
}

export interface TelemedicineAttendanceState {
  data: ITelemedicineAttendancesResponse;
  meet?: ITelemedicineAttendancesCreateMeetResponse;
  documentList: {
    items?: {
      simplePrescription?: ITelemedicinesAttendancesDocumentListResponse[];
      specialPrescription?: ITelemedicinesAttendancesDocumentListResponse[];
      medicalCertificate?: ITelemedicinesAttendancesDocumentListResponse[];
      initialCare?: ITelemedicinesAttendancesDocumentListResponse[];
    };
    page: string | number;
    limit: string | number;
    total: string | number;
  };
  biomeasures?: ITelemedicineAttendancesGetBiomeasuresResponse
  loading: boolean;
  success: boolean;
  error: boolean;
}
