import { put, call } from "redux-saga/effects";
import { toast } from "react-toastify";
import { AxiosResponse } from "axios";

import {
  loadSuccess,
  loadFailure,
  loadOpinionSuccess,
  loadOpinionFailure,
  createAnswerSuccess,
  createAnswerFailure
} from "./actions";

import { apiSollar } from "../../../services/axios";

const token = localStorage.getItem("token");

export function* get({ payload }: any) {
  try {
    const { params } = payload;
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/opinion/list?limit=${params.limit ? params.limit : 10}&page=${
        params.page || 1
      }${params.search ? "&search=" + params.search : ""}${
        params.status ? "&status=" + params.status : ""
      }${params.patient_id ? "&patient_id=" + params.patient_id : ""}`
    );

    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar a lista de parecer");
    yield put(loadFailure());
  }
}

export function* getData({ payload }: any) {
  try {
    const { params } = payload;
    let response: AxiosResponse;
    response = yield call(
      apiSollar.get as any,
      `/opinion/${params.id}`
    );

    yield put(loadOpinionSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao buscar os dados do parecer");
    yield put(loadOpinionFailure());
  }
}

export function* createAnswer({ payload }: any) {
  try {
    // alternativa, mudar depois para o redux-saga
    const path = payload.answer_id ? `/opinion/answer/cancel` : `/opinion/answer`

    let response: AxiosResponse;
    response = yield call(
      apiSollar.post as any,
      path,
      payload
    );

    yield put(createAnswerSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao criar a resposta do parecer!");
    yield put(createAnswerFailure());
  }
}
