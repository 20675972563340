import { call, put } from "redux-saga/effects";
import { apiSollar } from "../../../services/axios";
import { loadFailure, loadSuccess } from "./action";
import { toast } from "react-toastify";
import { IDigitalSignatureGetTokenPayload } from "./types";
import Cookies from "js-cookie";
import LOCALSTORAGE from "../../../helpers/constants/localStorage";
import { addHours, parseISO } from "date-fns";

const path = "/signature/auth";

export function* getToken({ payload }: IDigitalSignatureGetTokenPayload) {
  try {
    const response = yield call(
      apiSollar.post as any,
      `${path}`,
      {fiscal_number: payload.fiscal_number, password: payload.password}
    );
    if (response.data?.token) {
        Cookies.set("signer-auth-token", response.data?.token);
        localStorage.setItem(LOCALSTORAGE.SIGNER_TOKEN, response.data?.token);
        localStorage.setItem(LOCALSTORAGE.SIGNER_TOKEN_EXPIRED_AT, String(addHours(parseISO(response.data?.updated_at), 12).toISOString()))
        toast.success("Senha validada com sucesso")
    }
    yield put(loadSuccess(response.data));
  } catch (error) {
    toast.error("Erro ao tentar buscar token de assinatura");
    yield put(loadFailure());
  }
}
