import { action } from "typesafe-actions";
import { DigitalSignatureTypes, IDigitalSignatureGetTokenResponse } from "./types";

export const loadRequest = (body: {
  password: string;
  fiscal_number: string | number;
}) => action(DigitalSignatureTypes.LOAD_REQUEST, body);

export const loadSuccess = (data: IDigitalSignatureGetTokenResponse) =>
  action(DigitalSignatureTypes.LOAD_SUCCESS, data);

export const openPopup = () => action(DigitalSignatureTypes.OPEN_POPUP);

export const closePopup = () => action(DigitalSignatureTypes.CLOSE_POPUP);

export const loadFailure = () => action(DigitalSignatureTypes.LOAD_FAILURE);
