export enum DigitalSignatureTypes {
  OPEN_POPUP = "@digitalSignature/OPEN_POPUP",
  CLOSE_POPUP = "@digitalSignature/CLOSE_POPUP",
  LOAD_REQUEST = "@digitalSignature/LOAD_REQUEST",
  LOAD_SUCCESS = "@digitalSignature/LOAD_SUCCESS",
  LOAD_FAILURE = "@digitalSignature/LOAD_FAILURE",
}

export interface DigitalSignatureState {
  isOpen: boolean;
  data?: IDigitalSignatureGetTokenResponse;
  loading: boolean;
  success: boolean;
  error: boolean;
}

export interface IDigitalSignatureGetTokenPayload {
  type: typeof DigitalSignatureTypes.LOAD_REQUEST;
  payload: {
    fiscal_number: string | number;
    password: string;
  };
}

export interface IDigitalSignatureGetTokenResponse {
  active: boolean;
  _id: string;
  user_id: string,
  token: string,
  expiration_token: string,
  created_at: string,
  updated_at: string,
}
