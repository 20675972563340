import { Box, Button, Grid, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import AccordionComponent from "../../../../../components/AccordionComponent";
import DocumentIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useForm } from "react-hook-form";
import TextField from "../../../../../components/FormFields/TextField";
import theme from "../../../../../theme/theme";
import SwitchField from "../../../../../components/FormFields/SwitchField";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { useDispatch } from "react-redux";
import {
  loadRequestCancelDocument,
  loadRequestCreateDocument,
} from "../../../../../store/ducks/telemedicineAttendances/actions";
import { format, parseISO } from "date-fns";
import LOCALSTORAGE from "../../../../../helpers/constants/localStorage";
import { ITelemedicineAttendanceBiomeasures } from "../../../../../store/ducks/telemedicineAttendances/types";
import PaginatedAutocompleteField from "../../../../../components/FormFields/PaginatedAutocompleteField";
import { apiSollar } from "../../../../../services/axios";
import { toast } from "react-toastify";

interface IInitialCareProps {
  telemedicineId: string;
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  setCreateNewDocument: React.Dispatch<React.SetStateAction<boolean>>;
  createNewDocument: boolean;
  setOpenCall?: React.Dispatch<React.SetStateAction<boolean>>;
  inCall?: boolean;
  sx?: SxProps;
}

interface IKeyValuePairDescribeProps {
  value: string | number;
  label: string;
  xs?: number;
  md?: number;
  lg?: number;
  xl?: number;
  fontSize?: string | number;
}

const KeyValuePairDescribe = ({
  value,
  label,
  xs,
  md,
  lg,
  xl,
  fontSize,
}: IKeyValuePairDescribeProps) => (
  <Grid item xs={xs || 12} md={md} lg={lg} xl={xl} display="flex" gap={0.4}>
    <Typography fontWeight={550} fontSize={fontSize || 16}>
      {label}:
    </Typography>
    <Typography fontSize={fontSize || 16}>
      {!!value ? value : "Não informado"}
    </Typography>
  </Grid>
);

async function getCIDs(params?: any) {
  try {
    const response = await apiSollar.get("/cid/integration/show", {
      params,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export default function InitialCare({
  telemedicineId,
  setRefetch,
  createNewDocument = false,
  setCreateNewDocument,
  setOpenCall,
  inCall,
  sx,
}: IInitialCareProps) {
  const [cancelDocument, setCancelDocument] = useState(false);
  const userLogged = localStorage.getItem(LOCALSTORAGE.USER_ID);
  const dispatch = useDispatch();
  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      main_diagnoses: null,
      secondary_diagnoses: null,
      violence: false,
      open_field: "",
    },
  });

  const cancelForm = useForm({
    defaultValues: {
      canceled_reason: "",
    },
  });

  const biomeasure: ITelemedicineAttendanceBiomeasures =
    !!telemedicineAttendancesState.biomeasures
      ? telemedicineAttendancesState?.biomeasures[0]
      : null;

  return (
    <Box sx={sx}>
      {createNewDocument ? (
        <Grid
          container
          spacing={1.5}
          sx={{
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
          component="form"
          onSubmit={handleSubmit((values) => {
            let secondary_diagnoses = values.secondary_diagnoses?.cid_id
              ? `${values.secondary_diagnoses.cid_id} - ${values.secondary_diagnoses.name}`
              : null;
            let main_diagnoses = `${values.main_diagnoses?.cid_id} - ${values.main_diagnoses?.name}`;
            delete biomeasure?.total;
            delete biomeasure?.rn;
            delete values.main_diagnoses;
            delete values.secondary_diagnoses;
            dispatch(
              loadRequestCreateDocument({
                content: {
                  ...values,
                  secondary_diagnoses,
                  main_diagnoses,
                  ...biomeasure,
                },
                document_type: "InitialCare",
                telemedicine_attendance_id: telemedicineId,
              })
            );
            if (inCall) {
              setOpenCall(false);
            } else {
              setCreateNewDocument(false);
            }
            setRefetch(true);
            reset();
          })}
        >
          <Grid container paddingX={2} mb={2} alignItems="center">
            <Grid item xs={12}>
              <Typography fontSize={16} fontWeight={550} mb={0.5}>
                Alertas
              </Typography>
              <Typography fontSize={13} mb={1}>
                {biomeasure?.alerts}
              </Typography>
            </Grid>
            <Typography fontSize={16} fontWeight={550} mb={0.5}>
              Registro de Enfermagem
            </Typography>
            <KeyValuePairDescribe
              label="Prioridade"
              value={biomeasure?.priority}
              fontSize={13}
            />
            <KeyValuePairDescribe
              label="Queixa Principal"
              value={biomeasure?.main_complaint}
              fontSize={13}
            />
            <KeyValuePairDescribe
              label="Pressão Arterial"
              value={!!biomeasure?.pa ? biomeasure?.pa + " mmHg" : null}
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Frequência Cardíaca"
              value={!!biomeasure?.fc ? biomeasure?.fc + " bpm" : null}
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Frequência Respiratória"
              value={!!biomeasure?.fr ? biomeasure?.fr + " bpm" : null}
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Temperatura"
              value={
                !!biomeasure?.temperature
                  ? biomeasure?.temperature + " °C"
                  : null
              }
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Dextro"
              value={
                !!biomeasure?.dextro ? biomeasure?.dextro + " mg/dl" : null
              }
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Saturação"
              value={
                !!biomeasure?.saturation ? biomeasure?.saturation + " %" : null
              }
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
            <KeyValuePairDescribe
              label="Peso"
              value={!!biomeasure?.weight ? biomeasure?.weight + " Kg" : null}
              fontSize={13}
              xs={inCall ? 12 : 3}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Anamnese, Exame Físico e Conduta"
              control={control}
              name="open_field"
              multiline
              minRows={3}
              required
            />
          </Grid>
          <Grid item xs={inCall ? 12 : 6}>
            <PaginatedAutocompleteField
              control={control}
              required
              label="Hipótese Diagnóstica Principal"
              name="main_diagnoses"
              queryKey="cids_main"
              filterKey="describe"
              service={async (params) => {
                const regex = /^(?=.*[A-Z])(?=.*\d).+$/;
                let code, describe;

                if (!regex.test(params.describe)) {
                  describe = params.describe;
                } else {
                  code = params.describe;
                  delete params.describe;
                }
                const customParams = { describe, code, ...params };
                return getCIDs(customParams);
              }}
              AutocompleteProps={{
                getOptionLabel: (option: any) =>
                  `${option.cid_id ? option.cid_id + " -" : ""} ${option.name}`,
                noOptionsText: "Sem resultados. Tente outra busca",
              }}
              optionLabelKey="name"
            />
          </Grid>
          <Grid item xs={inCall ? 12 : 6}>
            <PaginatedAutocompleteField
              control={control}
              label="Hipótese Diagnóstica Secundária"
              name="secondary_diagnoses"
              queryKey="cids_secondary"
              filterKey="describe"
              service={async (params) => {
                const regex = /^(?=.*[A-Z])(?=.*\d).+$/;
                let code, describe;

                if (!regex.test(params.describe)) {
                  describe = params.describe;
                } else {
                  code = params.describe;
                  delete params.describe;
                }
                const customParams = { describe, code, ...params };
                return getCIDs(customParams);
              }}
              AutocompleteProps={{
                getOptionLabel: (option: any) =>
                  `${option.cid_id ? option.cid_id + " -" : ""} ${option.name}`,
                noOptionsText: "Sem resultados. Tente outra busca",
              }}
              optionLabelKey="name"
            />
          </Grid>
          <Grid item xs={12}>
            <SwitchField
              control={control}
              name="violence"
              label="Atendimento devido violência doméstica, sexual e/ou outras violências interpessoais?"
            />
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" flexDirection="column" gap={1} fontSize={12}>
              <Typography component="div" fontSize={12}>
                <Typography fontWeight={600} fontSize={12} component="span">
                  Definição de caso:
                </Typography>{" "}
                Considera-se violência como o uso intencional de força físicas
                ou poder, real ou em ameaça, contra si próprio, contra outra
                pessoa, ou contra um grupo ou uma comunidade que resulte ou
                tenha possibilidade de resultar em lesão, morte, dano
                psicológico, deficiência de desenvolvimento ou privação.
              </Typography>
              <Typography component="div" fontSize={12}>
                <Typography fontWeight={600} fontSize={12} component="span">
                  Atenção:
                </Typography>{" "}
                Em casos de suspeita ou confirmação de violência contra crianças
                e adolescentes, a notificação deve ser obrigatória e dirigida
                aos Conselhos Tutelares e autoridades competentes (Delegacias de
                Proteção da Criança e do Adolescente e Ministério Público da
                localidade), de acordo com o{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  art. 13 da Lei nº 8.069/1990 Estatuto da Criança e do
                  Adolescente.
                </Typography>{" "}
                Esta ficha atende ao{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  Decreto-Lei nº 5.099 de 03/06/2004
                </Typography>
                , que regulamenta a{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  Lei nº 10.778/2003
                </Typography>
                , que institui o serviço de notificação compulsória de{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  violência contra a mulher
                </Typography>
                , e o artigo{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  19 da Lel nº 10.741/2003
                </Typography>{" "}
                que prevê que os casos de suspeita ou confirmação de{" "}
                <Typography fontWeight={600} fontSize={12} component="span">
                  maus tratos contra idoso
                </Typography>{" "}
                são de notificação obrigatória.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="start">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      ) : (
        telemedicineAttendancesState?.documentList?.items?.initialCare?.map(
          (document) => {
            const documentCanceled = document?.canceled
              ? { textDecoration: "line-through" }
              : { textDecoration: "none" };
            return (
              <AccordionComponent
                configs={[
                  {
                    title: (
                      <Box display="flex" gap={0.3}>
                        <DocumentIcon />
                        <Typography sx={documentCanceled}>
                          {format(
                            parseISO(document?.created_at),
                            "dd/MM/yyyy 'às' HH:mm"
                          )}
                        </Typography>
                      </Box>
                    ),
                    body: (
                      <Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>
                            Hipótese Diagnóstica Principal:
                          </Typography>
                          <Typography>
                            {document?.content.main_diagnoses}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>
                            Hipótese Diagnóstica Secundária:
                          </Typography>
                          <Typography>
                            {document?.content.secondary_diagnoses}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>
                            Atendimento devido violência doméstica, sexual e/ou
                            outras violências interpessoais:
                          </Typography>
                          <Typography>
                            {document?.content.violence ? "Sim" : "Não"}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={0.4} sx={documentCanceled}>
                          <Typography fontWeight={550}>
                            Observações:{" "}
                          </Typography>
                          <Typography>
                            {document?.content.open_field}
                          </Typography>
                        </Box>
                        {document?.canceled_at && document?.canceled_reason && (
                          <>
                            <Box display={"flex"} gap={0.4}>
                              <Typography fontWeight={550}>
                                Cancelado em:
                              </Typography>
                              <Typography>
                                {format(
                                  parseISO(document?.canceled_at),
                                  "dd/MM/yyyy 'às' HH:mm"
                                )}
                              </Typography>
                            </Box>
                            <Box display={"flex"} gap={0.4}>
                              <Typography fontWeight={550}>
                                Razão do Cancelamento:
                              </Typography>
                              <Typography>
                                {document?.canceled_reason}
                              </Typography>
                            </Box>
                          </>
                        )}
                        {document?.created_by == userLogged &&
                          !document.canceled && (
                            <Button
                              sx={{ width: 110, mt: 1.5 }}
                              variant="contained"
                              color={cancelDocument ? "primary" : "error"}
                              onClick={() => setCancelDocument(!cancelDocument)}
                            >
                              {cancelDocument ? "Voltar" : "Cancelar"}
                            </Button>
                          )}
                        {cancelDocument && document?.created_by == userLogged && (
                          <Grid
                            sx={{
                              backgroundColor:
                                theme.palette.whiteBackgroud.main,
                              color: theme.palette.text.primary,
                              paddingX: 3,
                              paddingY: 3,
                              minHeight: "48px",
                            }}
                            container
                            spacing={2}
                            component="form"
                            onSubmit={cancelForm.handleSubmit((values) => {
                              dispatch(
                                loadRequestCancelDocument({
                                  canceled_reason: values.canceled_reason,
                                  _id: document._id,
                                })
                              );
                              setCancelDocument(!cancelDocument);
                              setCreateNewDocument(false);
                              setRefetch(true);
                              cancelForm.reset();
                            })}
                          >
                            <Grid item xs={12}>
                              <TextField
                                label="Razão do cancelamento"
                                control={cancelForm.control}
                                name="canceled_reason"
                                multiline
                                minRows={3}
                                required
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Button
                                color="error"
                                type="submit"
                                variant="contained"
                                sx={{ maxWidth: 180, width: "100%" }}
                              >
                                Confirmar
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                    ),
                  },
                ]}
                defaultOpen={false}
              />
            );
          }
        )
      )}
    </Box>
  );
}
