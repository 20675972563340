import React from 'react'
import { IDrawerProps } from './types'
import { Box, Drawer as MUIDrawer } from '@mui/material'

function Drawer({ open, handleClose, children, anchor = "left", props, sx }: IDrawerProps) {
  return (
    <Box sx={{width: 200}} role="presentation">
      <MUIDrawer open={open} anchor={anchor} onClose={handleClose} {...props}>
        {children}
      </MUIDrawer>
    </Box>
  )
}

export default Drawer
