import { Reducer } from "redux";
import {
  TelemedicineAttendanceState,
  TelemedicineAttendancesTypes,
} from "./types";

export const INITIAL_STATE: TelemedicineAttendanceState = {
  data: {
    attendance: null,
    items: [],
    limit: "10",
    page: "1",
    total: "10",
  },
  documentList: {
    items: null,
    limit: 10,
    page: 1,
    total: 1,
  },
  meet: null,
  biomeasures: null,
  loading: false,
  success: false,
  error: false,
};

const reducer: Reducer<TelemedicineAttendanceState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TelemedicineAttendancesTypes.LOAD_REQUEST:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_MEET:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_CREATE_DOCUMENT:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_GET_DOCUMENT:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_CANCEL_DOCUMENT:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_GET_BIOMEASURES:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_REQUEST_ATTENDANCE_BY_ID:
      return { ...state, loading: true, success: false };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS:
      const filteredAttendances = action.payload.data?.filter(
        (attendance) => !attendance.finished_at
      );
      return {
        ...state,
        data: { items: filteredAttendances, ...action.payload },
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_CREATE_MEET:
      return {
        ...state,
        meet: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_UPDATE_STATUS:
      return {
        ...state,
        meet: action.payload,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_CREATE_DOCUMENT:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_GET_DOCUMENT:
      let documentList = {
        initialCare: state.documentList.items?.initialCare,
        specialPrescription: state.documentList.items?.specialPrescription,
        simplePrescription: state.documentList.items?.simplePrescription,
        medicalCertificate: state.documentList.items?.medicalCertificate,
      };
      if (action.payload.document_type === "InitialCare") {
        documentList.initialCare = action.payload.data;
      }
      if (action.payload.document_type === "SimplePrescription") {
        documentList.simplePrescription = action.payload.data;
      }
      if (action.payload.document_type === "SpecialPrescription") {
        documentList.specialPrescription = action.payload.data;
      }
      if (action.payload.document_type === "MedicalCertificate") {
        documentList.medicalCertificate = action.payload.data;
      }
      return {
        ...state,
        documentList: { ...state.documentList, items: documentList },
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_CANCEL_DOCUMENT:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_GET_BIOMEASURES:
      return {
        ...state,
        biomeasures: action?.payload?.data,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_SUCCESS_ATTENDANCE_BY_ID:
      return {
        ...state,
        data: { ...state.data, attendance: action.payload },
        documentList: { ...state.documentList, items: action.payload.documents || null },
        meet: null,
        loading: false,
        success: true,
        error: false,
      };
    case TelemedicineAttendancesTypes.LOAD_FAILURE:
      return {
        ...state,
        documentList: {
          items: null,
          page: "1",
          total: "0",
          limit: "100",
        },
        meet: null,
        biomeasures: null,
        loading: false,
        error: true,
        success: false,
      };
    default:
      return state;
  }
};

export default reducer;
