import {
  Container,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { useDispatch } from "react-redux";
import { ITelemedicineAttendances } from "../../../store/ducks/telemedicineAttendances/types";
import { RequestParamsDataSync } from "../../../store/ducks/integrationDocuments/sagas";
import { loadRequestLogs } from "../../../store/ducks/integrationDocuments/actions";
import { format, parseISO } from "date-fns";
import PaginationComponent from "../../../components/Pagination";

interface ViewedDocumentHistoryProps {
  currentAttendance: ITelemedicineAttendances;
  payload: RequestParamsDataSync;
}

export default function ViewedDocumentHistory({
  currentAttendance,
  payload,
}: ViewedDocumentHistoryProps) {
  const [page, setPage] = React.useState<number | string>(1);
  const [limit, setLimit] = React.useState<number | string>(5);
  const dispatch = useDispatch();
  const integrationDocumentsState = useSelector(
    (state: ApplicationState) => state.integrationDocuments
  );

  const columns = [
    {
      label: "Nome",
    },
    {
      label: "Usuário",
    },
    {
      label: "Data/Hora",
    },
  ];

  useEffect(() => {
    dispatch(loadRequestLogs({...payload, page, limit}));
  }, [currentAttendance, page, limit]);

  console.log(integrationDocumentsState);

  return (
    <Container>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            {columns.map((column, index) => (
              <TableCell key={index}>{column.label}</TableCell>
            ))}
          </TableHead>
          {integrationDocumentsState.data.logs?.items.map((row, index) => (
            <TableRow>
              <TableCell>{row.visit_by?.name}</TableCell>
              <TableCell>{row.visit_by?.username}</TableCell>
              <TableCell>
                {format(parseISO(row.visit_at), "dd/MM/yyyy 'às' HH:mm")}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
      <PaginationComponent
        page={String(page)}
        rowsPerPage={String(limit)}
        totalRows={Number(integrationDocumentsState.data.logs.total || 0)}
        handleFirstPage={() => setPage("1")}
        handleLastPage={() =>
          dispatch(
            loadRequestLogs({
              page: Math.ceil(
                +integrationDocumentsState.data.logs.total / +limit
              ).toString(),
              limit: limit.toString(),
              ...payload,
            })
          )
        }
        handleNextPage={() => setPage(Number(page) + 1)}
        handlePreviosPage={() => setPage(Number(page) - 1)}
        handleChangeRowsPerPage={(event) => setLimit(event.target.value)}
      />
    </Container>
  );
}
