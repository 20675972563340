import React from "react";
import DialogMedium from "../DialogMedium";
import { Button, Grid } from "@mui/material";
import TextField from "../FormFields/TextField";
import { useForm } from "react-hook-form";
import { apiSollar } from "../../services/axios";
import Cookies from "js-cookie";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import {
  closePopup,
  loadRequest,
} from "../../store/ducks/digitalSignature/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";

interface ISignaturePasswordProps {
  open: boolean;
  handleClose?: () => void;
}

function SignaturePassword({ open, handleClose }: ISignaturePasswordProps) {
  const userState = useSelector((state: ApplicationState) => state.users);
  const dispatch = useDispatch();
  const validations = yup.object().shape({
    password: yup.string().required("É requerido"),
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      password: "",
    },
    resolver: yupResolver(validations),
  });

  function handleCancel() {
    apiSollar.defaults.headers["signer-auth-token"] = null;
    Cookies.set("signer-auth-token", null);
  }

  return (
    <>
      <DialogMedium
        title={"Confirmar senha da assinatura digital"}
        open={open}
        fullWidth={true}
        maxWidth="sm"
        handleClose={() => {
          !!handleClose && handleClose();
          dispatch(closePopup());
        }}
      >
        <Grid
          container
          spacing={2}
          component="form"
          onSubmit={handleSubmit((values) => {
            dispatch(
              loadRequest({
                fiscal_number: userState.data.fiscal_number,
                ...values,
              })
            );
            reset();
          })}
        >
          <Grid item xs={12} mb={2}>
            <TextField
              control={control}
              password
              type="password"
              name="password"
              label="Senha:"
              sx={{ background: "white" }}
            />
          </Grid>
          <Grid item xs={3}>
            <Button type="submit" variant="contained">
              Confirmar
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              onClick={() => {
                handleCancel();
                !!handleClose && handleClose();
                dispatch(closePopup());
              }}
              color="error"
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
    </>
  );
}

export default SignaturePassword;
