export enum RevaluationTypes {
  LOAD_REQUEST = "@revaluation/LOAD_REQUEST",
  LOAD_REQUEST_CREATE_ATTENDANCE = "@revaluation/LOAD_REQUEST_CREATE_ATTENDANCE",
  LOAD_SUCCESS = "@revaluation/LOAD_SUCCESS",
  LOAD_SUCCESS_CREATE_ATTENDANCE = "@revaluation/LOAD_SUCCESS_CREATE_ATTENDANCE",
  LOAD_REQUEST_GET_ATTENDANCE_BY_ID = "@revaluation/LOAD_REQUEST_GET_ATTENDANCE_BY_ID",
  LOAD_SUCCESS_GET_ATTENDANCE_BY_ID = "@revaluation/LOAD_SUCCESS_GET_ATTENDANCE_BY_ID",
  LOAD_REQUEST_UPDATE_STATUS = "@revaluation/LOAD_REQUEST_UPDATE_STATUS",
  LOAD_SUCCESS_UPDATE_STATUS = "@revaluation/LOAD_SUCCESS_UPDATE_STATUS",
  LOAD_REQUEST_GET_EXAMS = "@revaluation/LOAD_REQUEST_GET_EXAMS",
  LOAD_SUCCESS_GET_EXAMS = "@revaluation/LOAD_SUCCESS_GET_EXAMS",
  LOAD_FAILURE = "@revaluation/LOAD_FAILURE",
}

export type StatusParams =  "available" | "inProgress" | "finished";
export interface IRevaluationRequestParams {
  page: string;
  limit: string;
  status?: "revaluate" | "finished";
}

export interface IRevaluationGetAttendanceByIdParams {
  type: typeof RevaluationTypes.LOAD_REQUEST_GET_ATTENDANCE_BY_ID;
  payload: { external_attendance_id: string | number };
}

export interface IRevaluationRequestPayload {
  type: typeof RevaluationTypes.LOAD_REQUEST;
  payload: IRevaluationRequestParams;
}

export interface IRevaluationCreateAttendanceBody {
  external_attendance_id: string | number;
  external_patient_id: string | number;
}

export interface IRevaluationCreateAttendancePayload {
  type: typeof RevaluationTypes.LOAD_REQUEST_CREATE_ATTENDANCE;
  payload: IRevaluationCreateAttendanceBody;
}

export interface IRevaluationUpdateStatusParams {
  _id: string;
  status?: StatusParams;
}

export interface IRevaluationUpdateStatusPayload {
  type: typeof RevaluationTypes.LOAD_REQUEST_UPDATE_STATUS;
  payload: IRevaluationUpdateStatusParams;
}

export interface IRevaluationGetExamsParams {
  attendance: string | number;
  page: string | number;
  limit: string | number;
}

export interface IRevaluationGetExamsPayload {
  type: typeof RevaluationTypes.LOAD_REQUEST_GET_EXAMS;
  payload: IRevaluationGetExamsParams;
}

export interface IRevaluation {
  id_attendance: number;
  id_medical_record: number;
  patient_name: string;
  birthdate: string;
  gender: string;
  marital_status: string;
  mother_name: string;
  ident_national: string;
  physic_national: string;
  phone_number: string;
  email: string;
  sector_name: string;
  specialty: string;
  employee_name: string;
  attendance_date: string;
  insurance: string;
  plan: string;
  classication_color: string;
  _id?: string;
  status?: string;
}

export interface IRevaluationExams {
  id_attendance: string | number;
  request_date: string;
  has_result: string;
  id_lab_exam: string | number;
  lab_exam_name: string;
}

export interface IRevaluationResponse {
  items: IRevaluation[];
  page: string | number;
  limit: number;
  total: number | string;
}

export interface IRevaluationGetAttendanceByIdResponse {
  status: StatusParams;
  company_id: string;
  created_at: string;
  external_ids: {
    patient: string;
    attendance: string;
  };
  finished_at: string;
  finished_by: string;
  _id: string;
}

export interface IRevaluationGetExamsResponse {
  items: IRevaluationExams[];
  pages: { total: string | number; current: string | number };
  limit: string | number
  totalItems: string | number;
  attendance: string | number;
  page: string | number;
}

export interface RevaluationState {
  data: IRevaluationResponse;
  attendance?: IRevaluationGetAttendanceByIdResponse;
  exams?: IRevaluationGetExamsResponse;
  loading: boolean;
  success: boolean;
  error: boolean;
}
