import React, { useEffect, useState } from "react";
// components
import Sidebar from "../../../components/Sidebar";
import ButtonTabs, { IButtons } from "../../../components/Button/ButtonTabs";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import Header from "../../../components/Header/Overview";
import CardInfo from "../../../components/Card/Info";
// services
import { useDispatch, useSelector } from "react-redux";
import SESSIONSTORAGE from "../../../helpers/constants/sessionStorage";
import { ApplicationState } from "../../../store";
import {
  loadCreateAttendance,
  loadRequestGetAttendanceById,
  loadRequestGetExams,
  loadRequestUpdateStatus,
} from "../../../store/ducks/revaluation/actions";
import openURL from "../../../utils/openURL";
import { ReactComponent as ExamLab } from "../../../assets/img/icon-exam-lab.svg";
import theme from "../../../theme/theme";

export default function RevaluationOverview() {
  const integration = sessionStorage.getItem(SESSIONSTORAGE.INTEGRATION);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [refetch, setRefetch] = useState(false);

  const revaluationState = useSelector(
    (state: ApplicationState) => state.revaluation
  );

  const currentAttendance = revaluationState.data.items?.find(
    (attendance) => attendance.id_attendance === Number(params.id)
  );

  const attendance = revaluationState?.attendance

  useEffect(() => {
    if (!currentAttendance) {
      navigate("/revaluation");
    } else {
      dispatch(
        loadRequestGetExams({
          attendance: currentAttendance?.id_attendance,
          page: 1,
          limit: 100,
        })
      );
      dispatch(loadRequestGetAttendanceById({external_attendance_id: params.id}))
      setRefetch(false);
    }
  }, [refetch]);

  const buttons: IButtons[] = [
    {
      name: "Voltar",
      onClick: () => {
        navigate("/revaluation");
      },
      variant: "contained",
      background: "primary",
      show: true,
    },
  ];
  const handleCopy = () => {
    if (!attendance?.status && !attendance?._id) {
      dispatch(
        loadCreateAttendance({
          external_attendance_id: currentAttendance.id_attendance,
          external_patient_id: currentAttendance.id_medical_record,
        })
      );
      setRefetch(true);
    }
  };

  const rows = [
    { name: "Nome", value: currentAttendance?.patient_name },
    {
      name: "Gênero",
      value: currentAttendance?.gender === "F" ? "Feminino" : "Masculino",
    },
    { name: "Estado Civil", value: currentAttendance?.marital_status },
    { name: "Data de nascimento", value: currentAttendance?.birthdate },
    { name: "Mãe", value: currentAttendance?.mother_name },
    { name: "RG", value: currentAttendance?.ident_national },
    { name: "CPF", value: currentAttendance?.physic_national },
    { name: "Celular", value: currentAttendance?.phone_number },
    { name: "Email", value: currentAttendance?.email },
    {
      name: "Código do Prontuário",
      value: currentAttendance?.id_medical_record,
    },
    { name: "Número do Atendimento", value: currentAttendance?.id_attendance },
    { name: "Médico Assistente", value: currentAttendance?.employee_name },
    { name: "Data de Atendimento", value: currentAttendance?.attendance_date },
    { name: "Setor", value: currentAttendance?.sector_name },
    {
      name: "Especialidade do Atendimento",
      value: currentAttendance?.specialty,
    },
    { name: "Convênio", value: { name: currentAttendance?.insurance } },
    { name: "Plano", value: { name: currentAttendance?.plan } },
  ];

  const content = {
    tittle: "HeaderOverview",
    rows: rows,
    qrCodeState: [],
    careState: [],
    onClickJoinMeet: () => {
      if (currentAttendance?.status === "inProgress") {
        openURL(
          `${window.location.origin}/call/revaluation/${currentAttendance?.id_attendance}`
        );
        return;
      }
      dispatch(
        loadRequestUpdateStatus({
          _id: currentAttendance?._id,
          status: "inProgress",
        })
      );
      openURL(
        `${window.location.origin}/call/currentAttendance/${currentAttendance?.id_attendance}`
      );
      setRefetch(true);
    },
    onClickFinish: () => {
      dispatch(
        loadRequestUpdateStatus({
          _id: currentAttendance?._id,
          status: "finished",
        })
      );
      navigate("/revaluation");
    },
    onClickCopy: () => handleCopy(),
  };

  const personalCard = {
    card: "Dados Pessoais",
    info: ["Dados Pessoais"],
  };
  const planCard = {
    card: "Plano e Internação",
    info: ["Dados de atendimento", "Dados do Plano"],
  };

  const gridPropsPlan = {
    lg: 6,
    xl: 6,
    sx: 6,
    md: 6,
  };

  const tableHeaders = [
    "Cód. Exame",
    "Exame",
    "Data de Solicitação",
    "Resultado",
  ];

  return (
    <Sidebar>
      <Container sx={{ padding: "20px" }}>
        <Typography variant="h5" fontWeight={700} mb={5} color="primary">
          Overview de Paciente
        </Typography>
        <Grid container sx={{ background: "rgb(245, 245, 245)" }}>
          <Header
            content={content}
            opinion="revaluation"
            attendance={{...currentAttendance, status: attendance?.status}}
            disableActions={(revaluation, action) => {
              if (!revaluation?.status && action !== "copy") {
                return true;
              }
              if (revaluation?.status === "finished") {
                return true
              }
              if (
                revaluation?._id &&
                revaluation?.status === "available" &&
                action === "finish"
              ) {
                return true;
              }
              return false;
            }}
          />
          <Grid
            container
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              paddingY: 1,
              width: "calc(100% - 68px)",
              margin: "0 auto",
            }}
          >
            <CardInfo
              content={content}
              tittle={personalCard}
              alergicIs={true}
              gridProps={gridPropsPlan}
              integration={integration}
            />

            <CardInfo
              content={content}
              tittle={planCard}
              alergicIs={false}
              gridProps={gridPropsPlan}
              integration={integration}
            />
          </Grid>
          <Grid item xs={12} mx={5}>
            <Typography variant="subtitle1" fontWeight={700} color="primary">
              Resultado de Exames
            </Typography>
            {revaluationState.exams?.items?.length ? (
              <TableContainer component={Paper} sx={{ my: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map((header) => (
                        <TableCell>{header}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {revaluationState.exams?.items?.map((item) => (
                      <TableRow>
                        <TableCell>{item.id_lab_exam}</TableCell>
                        <TableCell>{item.lab_exam_name}</TableCell>
                        <TableCell>{item.request_date}</TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              item.has_result === "S"
                                ? "Liberado"
                                : "Não liberado"
                            }
                          >
                            <ExamLab
                              style={{
                                width: 30,
                                height: 30,
                                fill:
                                  item.has_result === "S"
                                    ? "#2e993a"
                                    : theme.palette.error.main,
                              }}
                            />
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography mb={1}>Nenhum resultado informado</Typography>
            )}
          </Grid>
        </Grid>
        <ButtonTabs buttons={buttons} canEdit={false} />
      </Container>
    </Sidebar>
  );
}
