import { Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import TextField from "../../../components/FormFields/TextField";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import { createUserRequest } from "../../../store/ducks/users/actions";
import { useDispatch } from "react-redux";
import RegexOf from "../../../utils/regex";
import { cleanUpMask } from "../../../utils/mask";
import PaginatedAutocompleteField from "../../../components/FormFields/PaginatedAutocompleteField";
import { apiSollar } from "../../../services/axios";
import { toast } from "react-toastify";
import { ufOptions } from "../../../utils/optionsToFields";

interface IUserRegisterProps {
  onCancel?: () => void;
}

async function getCompanies(params?: any) {
  try {
    const response = await apiSollar.get("/companies/list", {
      params,
    });
    return response.data;
  } catch (error: any) {
    toast.error(error.response.data.message);
  }
}

export default function UserRegister({ onCancel }: IUserRegisterProps) {
  const initialValues = {
    name: "",
    email: "",
    fiscal_number: "",
    council: "",
    council_state: null,
    council_number: "",
    companies: null,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Campo obrigatório"),
    email: Yup.string()
      .required("Campo obrigatório")
      .email("Formato de e-mail incorreto"),
    fiscal_number: Yup.string().required("Campo obrigatório").nullable(),
    council: Yup.string().required("Campo obrigatório").nullable(),
    council_state: Yup.object().required("Campo obrigatório").nullable(),
    council_number: Yup.string().required("Campo obrigatório").nullable(),
    companies: Yup.object().required("Campo obrigatório").nullable(),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const dispatch = useDispatch();

  const onSubmit = (data: any) => {
    data.council_state = data.council_state.describe;
    data.fiscal_number = cleanUpMask(data.fiscal_number, "", ["_", "-", "."]);
    data.companies = [data.companies._id]

    dispatch(createUserRequest(data));
    onCancel();
    reset();
  };

  return (
    <Grid
      component="form"
      container
      spacing={1.5}
      sx={{ background: "rgb(245, 245, 245)", borderRadius: 1, padding: 1.5 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item xs={12}>
        <Typography variant="subtitle1" fontWeight={700} color="primary">
          Cadastro de Usuário
        </Typography>
        <Divider sx={{ mb: 1 }} />
      </Grid>
      <Grid item md={7} xs={12}>
        <TextField control={control} name="name" label="Nome" required />
      </Grid>
      <Grid item md={5} xs={12}>
        <TextField control={control} name="email" label="Email" required />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          control={control}
          name="fiscal_number"
          label="CPF"
          mask={RegexOf.cpf}
          required
        />
      </Grid>
      {/* <Grid item md={6} xs={12}>
        <AutocompleteField
          control={control}
          name="specialties"
          label="Especialidades"
          options={[
            { describe: "Especialidade 1" },
            { describe: "Especialidade 2" },
            { describe: "Especialidade 3" },
            { describe: "Especialidade 4" },
            { describe: "Especialidade 5" },
          ]}
          optionLabelKey={"describe"}
          multiple
          AutocompleteProps={{
            getOptionDisabled: () => (specialties.length === 3 ? true : false),
            disableCloseOnSelect: true,
          }}
        />
      </Grid> */}
      <Grid item md={6} xs={6}>
        <TextField
          control={control}
          name="council"
          label="Conselho"
          type="text"
        />
      </Grid>
      <Grid item md={6} xs={6}>
        <AutocompleteField
          control={control}
          name="council_state"
          label="UF Conselho"
          options={ufOptions}
          optionLabelKey={"describe"}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextField
          control={control}
          name="council_number"
          label="Código Conselho"
          type="number"
        />
      </Grid>
      <Grid item xs={12}>
        <PaginatedAutocompleteField
          control={control}
          required
          label="Escolha a empresa que deseja acessar"
          name="companies"
          queryKey="companies"
          filterKey="name"
          service={async (params) => getCompanies(params)}
          AutocompleteProps={{
            noOptionsText: "Sem resultados. Tente outra busca",
          }}
          optionLabelKey="name"
        />
      </Grid>
      <Grid item xs={12} display={"flex"} gap={2.5} mb={1}>
        <Button type="submit" variant="contained" color="secondary">
          Cadastrar
        </Button>
        <Button
          onClick={() => {
            onCancel();
            reset();
          }}
          variant="outlined"
          color="secondary"
        >
          Cancelar
        </Button>
      </Grid>
    </Grid>
  );
}
