import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import AccordionComponent from "../../../../../components/AccordionComponent";
import DocumentIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useForm } from "react-hook-form";
import TextField from "../../../../../components/FormFields/TextField";
import theme from "../../../../../theme/theme";
import {
  loadRequestCancelDocument,
  loadRequestCreateDocument,
} from "../../../../../store/ducks/telemedicineAttendances/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { format, parseISO } from "date-fns";
import LOCALSTORAGE from "../../../../../helpers/constants/localStorage";

type PrescriptionTypes = "simple" | "special";

interface IPrescriptionProps {
  type: PrescriptionTypes;
  telemedicineId: string;
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  createNewDocument: boolean;
  setCreateNewDocument?: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenCall?: React.Dispatch<React.SetStateAction<boolean>>;
  inCall?: boolean;
}

export default function Prescription({
  type,
  telemedicineId,
  setRefetch,
  createNewDocument = false,
  setCreateNewDocument,
  setOpenCall,
  inCall,
}: IPrescriptionProps) {
  const userLogged = localStorage.getItem(LOCALSTORAGE.USER_ID);
  const [cancelDocument, setCancelDocument] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      open_field: "",
    },
  });

  const cancelForm = useForm({
    defaultValues: {
      canceled_reason: "",
    },
  });

  const telemedicineAttendancesState = useSelector(
    (state: ApplicationState) => state.telemedicineAttendances
  );

  const documentList = type === "simple" ? telemedicineAttendancesState.documentList.items?.simplePrescription : telemedicineAttendancesState.documentList.items?.specialPrescription

  return (
    <Box>
      {createNewDocument ? (
        <Grid
          container
          spacing={1.5}
          sx={{
            backgroundColor: theme.palette.whiteBackgroud.main,
            color: theme.palette.text.primary,
            paddingX: 3,
            paddingY: 2,
            minHeight: "48px",
          }}
          component="form"
          onSubmit={handleSubmit((values) => {
            dispatch(
              loadRequestCreateDocument({
                content: values,
                document_type:
                  type === "simple"
                    ? "SimplePrescription"
                    : "SpecialPrescription",
                telemedicine_attendance_id: telemedicineId,
              })
            );
            if (inCall) {
              setOpenCall(false);
            } else {
              setCreateNewDocument(false);
            }
            setRefetch(true);
            reset();
          })}
        >
          <Grid item xs={12}>
            <TextField
              required
              label={
                type === "simple"
                  ? "Receituário"
                  : "Receituário Controle Especial"
              }
              control={control}
              name="open_field"
              multiline
              minRows={5}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="start">
            <Button type="submit" variant="contained">
              Salvar
            </Button>
          </Grid>
        </Grid>
      ) : (
        documentList?.map((document) => {
          const documentCanceled = document?.canceled
            ? { textDecoration: "line-through" }
            : { textDecoration: "none" };
          return (
            <AccordionComponent
              configs={[
                {
                  title: (
                    <Box display="flex" gap={0.3}>
                      <DocumentIcon />
                      <Typography sx={documentCanceled}>
                        {format(
                          parseISO(document?.created_at),
                          "dd/MM/yyyy 'às' HH:mm"
                        )}
                      </Typography>
                    </Box>
                  ),
                  body: (
                    <Box>
                      <Box display="flex" gap={0.4} sx={documentCanceled}>
                        <Typography fontWeight={550}>
                          {type === "simple"
                            ? "Receituário"
                            : "Receituário Controle Especial"}
                        </Typography>
                        <Typography>{document?.content?.open_field}</Typography>
                      </Box>
                      {document?.canceled_at && document?.canceled_reason && (
                        <>
                          <Box display={"flex"} gap={0.4}>
                            <Typography fontWeight={550}>
                              Cancelado em:
                            </Typography>
                            <Typography>
                              {format(
                                parseISO(document?.canceled_at),
                                "dd/MM/yyyy 'às' HH:mm"
                              )}
                            </Typography>
                          </Box>
                          <Box display={"flex"} gap={0.4}>
                            <Typography fontWeight={550}>
                              Razão do Cancelamento:
                            </Typography>
                            <Typography>{document?.canceled_reason}</Typography>
                          </Box>
                        </>
                      )}
                      {document?.created_by == userLogged &&
                        !document.canceled && (
                          <Button
                            sx={{ width: 110, mt: 1.5 }}
                            variant="contained"
                            color={cancelDocument ? "primary" : "error"}
                            onClick={() => setCancelDocument(!cancelDocument)}
                          >
                            {cancelDocument ? "Voltar" : "Cancelar"}
                          </Button>
                        )}
                      {cancelDocument && document?.created_by == userLogged && (
                        <Grid
                          sx={{
                            backgroundColor: theme.palette.whiteBackgroud.main,
                            color: theme.palette.text.primary,
                            paddingX: 3,
                            paddingY: 3,
                            minHeight: "48px",
                          }}
                          container
                          spacing={2}
                          component="form"
                          onSubmit={cancelForm.handleSubmit((values) => {
                            dispatch(
                              loadRequestCancelDocument({
                                canceled_reason: values.canceled_reason,
                                _id: document._id,
                              })
                            );
                            setCancelDocument(!cancelDocument);
                            setRefetch(true);
                            cancelForm.reset();
                          })}
                        >
                          <Grid item xs={12}>
                            <TextField
                              label="Razão do cancelamento"
                              control={cancelForm.control}
                              name="canceled_reason"
                              multiline
                              minRows={3}
                              required
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Button
                              color="error"
                              type="submit"
                              variant="contained"
                              sx={{ maxWidth: 180, width: "100%" }}
                            >
                              Confirmar
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  ),
                },
              ]}
              defaultOpen={false}
            />
          );
        })
      )}
    </Box>
  );
}
