import { useEffect } from "react";

const useScroll = (reference: any, time: number, disable = false, onEnd?: () => void) => {
  const container = reference.current;
  useEffect(() => {
    if (!!container && disable) {
      const seconds = time * 300;
      const incrementTime = seconds / container.scrollHeight;
      let scrollTop = 0;

      const interval = setInterval(() => {
        if (scrollTop + container.clientHeight === container.scrollHeight) {
          scrollTop = 0;
          if (!!onEnd) onEnd();
        } else {
          scrollTop += 1;
        }
        container.scrollTop = scrollTop;
      }, incrementTime);

      return () => clearInterval(interval);
    }
  }, [container, time, onEnd, disable]);
};

export default useScroll;
